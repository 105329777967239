import React, {Component, Fragment} from "react";
import {actions} from "react-fields";
import moment from 'moment';
import PaginationButtons from "../../admin_components/partials/paginationButtons";

import store from '../store/store';
import {setArticlesTab} from '../actions/navigationActions';
import ListTop from "../partials/listTop";
import DropDownButton from "../dropDownButton";
import ConfirmDeleteModal from './partials/confirmDeleteModal';

import ArticlesApi from '../../admin_components/api/articlesApi';

const FILTERS_LIST = [
    {value: '', name: 'All'},
    {value: 'draft', name: 'Draft'},
    {value: 'review', name: 'Review'},
    {value: 'rejected', name: 'Rejected'},
    {value: 'scheduled', name: 'Scheduled'},
    {value: 'published', name: 'Published'},
];

const SORT_LIST = [
    {value: 'desc', name: 'Newest'},
    {value: 'asc', name: 'Oldest'},
];

class ArticlesIndex extends Component {

    state = {articlesList: [], currentPage: 1, currentFilter: null, currentSort: null};

    componentDidMount() {
        store.dispatch(setArticlesTab());
        this.loadArticles(1);
    }

    handleSortChange = (sort) => {
        this.loadArticles(1, {state: this.state.currentFilter ? this.state.currentFilter.value : '', sort: sort.value});
        this.setState({currentSort: sort});
    };

    handleFilterChange = (filter) => {
        this.loadArticles(1, {state: filter.value, sort: this.state.currentSort ? this.state.currentSort.value : ''});
        this.setState({currentFilter: filter});
    };

    loadArticles = (page, options) => {
        let _this = this;
        let url = '/api/articles';
        let params = Object.assign({}, {page: page}, options);
        let paramsStr = Object.keys(params).map(function(k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
        }).join('&');
        fetch(actions.getData(`${url}?${paramsStr}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                let setSettings = {articlesList: data.articles || [], currentPage: page, totalPages: data.total};
                if (!_this.state.count) { setSettings['count'] = data.count }
                _this.setState(setSettings);
            });
    };

    onSuccessDelete = (articleId, response) => {
        let articles = this.state.articlesList.filter(function(a) {return a.id !== articleId;});
        this.setState({articlesList: articles});
    };

    onErrorDelete = (errors) => {
        console.log('onErrorDelete', errors);
    };

    onDelete = (articleId) => {
        console.log('onDelete', articleId);
        ArticlesApi.delete(articleId, 'author', (response) => this.onSuccessDelete(articleId, response), this.onErrorDelete)
    };

    isLive = (article) => {
      return article.publish_date && moment().isSameOrAfter(article.publish_date);
    };

    articleStateInfo = (article) => {
        switch (article.state) {
          case 'draft':
              return {class: 'saved-draft', title: 'Saved Draft'};
          case 'rejected':
              return {class: 'rejected', title: 'Rejected'};
          case 'published':
              return this.isLive(article) ?
                  {class: 'published', title: 'Published'} :
                  {class: 'scheduled', title: 'Scheduled'};
          default:
              return {class: 'under-review', title: 'Under Review'};
      }
    };

    renderButtons = (article) => {
            return (<div>
                <a className="small-icon eye" href={article.state === 'published' && this.isLive(article) ? article.path : `/account/article/${article.id}`} target='_blank' />
                {(article.state === 'draft' || article.state === 'rejected') ?
                    <Fragment>
                        <a className="small-icon pencil" href={`/account/submit/${article.id}`}/>
                        <div className="small-icon bin" onClick={() => this.refs.confirmDeleteModal.open(article.id)} />
                    </Fragment>
                : <div className='disabled'><span className="small-icon pencil"/><span className="small-icon bin"/></div>}
            </div>)
    };

    renderArticle = (article) => {
        let stateInfo = this.articleStateInfo(article);
        return(<div className={`grid-x grid-padding-x article-entry ${stateInfo.class}`} key={`article${article.id}`}>
            <div className="large-8 cell">
                        <h4>{article.name}</h4>
                        <div className="category-date">
                            <p className="article-date">{article.state === 'published' ? article.publish_date_formatted : article.updated_at_formatted}</p>
                        </div>
            </div>
            <div className="large-4 cell">
                <div className="grid-x article-details">
                    <div className="small-12 cell large-text-right">

                            <div className='status'>
                                <div className="article-status align-middle">
                                    <p className="status-flag">{stateInfo.title}</p>
                                </div>
                                <div className='edit-buttons'>
                                {this.renderButtons(article)}
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </div>)
    };

    render() {
        let _this = this;
        return (
            <Fragment>
                <ListTop count={this.state.count} header='Article'>
                    <div className="filter-buttons text-right">
                        <DropDownButton name='Sort' icon='sort-arrow' value={this.state.currentSort} onChange={this.handleSortChange}
                                        options={SORT_LIST} optionsLabel='name' />
                        {' '}
                        <DropDownButton name='Filter' icon='filter-arrow' value={this.state.currentFilter} onChange={this.handleFilterChange}
                                            options={FILTERS_LIST} optionsLabel='name' />
                    </div>
                </ListTop>
                {this.state.articlesList.length === 0 ?
                    <div className="small-12 cell text-center">
                        <div className="page-icon no-results-icon" style={{margin: '40px auto 20px auto'}}/>
                        <h3>You haven’t written any articles yet.</h3></div> :
                    this.state.articlesList.map(function (article) {
                        return (_this.renderArticle(article))
                    })}
                <div className="grid-x">
                    <div className="small-12 cell">
                        <PaginationButtons currentPage={this.state.currentPage} totalPages={this.state.totalPages} onChangePage={this.loadArticles} />
                    </div>
                </div>
                <ConfirmDeleteModal ref='confirmDeleteModal' title="Are you sure?"
                                    sprite="ad-icon warning"
                                    button="Close"
                                    onButtonClick={this.onDelete} />
            </Fragment>
        )
    }

}

export default ArticlesIndex;