import React, { Component, Fragment } from 'react';
import * as galleryActions from "../actions/galleryActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CF from "../common";
import GalleryApi from "../api/galleryApi";
import {ErrorMessage, Loader, SelectField} from "react-fields";
import DropDownButton from "../../components/dropDownButton";

const IMAGE_TYPES = [
    {value: 'backend_image', label: 'Backend Images'},
    {value: 'article_image', label: 'Body Images'},
    {value: 'article_featured_image', label: 'Featured Images'},
    {value: 'gallery_file', label: 'File'}
];

const FORMATS_ACCEPTED = {
    article_featured_image: '.jpg, .jpeg, .png',
    article_image: '.jpg, .jpeg, .png, .gif',
    backend_image: '.jpg, .jpeg, .png, .gif',
    gallery_file: '.doc, .pdf, .docx, .mp4, .mp3'
};

const FILE_REQUIREMENTS = {
    article_featured_image: [<p key='reqDimensions'>Dimensions: min. 1200px x 686px</p>, <p key='reqFileSize'>File Size: max. 500kb</p>],
    article_image: [<p key='reqFileSize'>File Size: max. 500kb</p>],
    backend_image: [<p key='reqFileSize'>File Size: max. 500kb</p>],
    gallery_file: [<p key='reqFileSize'>File Size: max. 5mb</p>]
};

class ItemUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: CF.generateUniqKey(),
            selectedType: null,
            acceptText: this.props.acceptText || ".png, .jpg, .jpeg",
            isLoading: false,
            errors: []
        };
        this.fileField = React.createRef();
    }

    onChangeFilter = (e) => {
        this.setState({selectedType: e.target.value, errors: []});
    };

    uploadImage = (file, imageType) => {
        let data = new FormData();
        data.append('file[data]', file);
        if (this.props.gallery.mode === 'admin' && this.props.gallery.reUploadItem) {
            data.append('id', this.props.gallery.reUploadItem.id);
        }
        data.append('type', imageType);
        data.append('mode', this.props.gallery.mode);
        GalleryApi.upload(data, this.onLoadFileSuccess, this.onLoadFileError);
    };

    onLoadFileSuccess = (response) => {
        this.setState({isLoading: false});
        let newItem = response['item'];
        newItem['new'] = true;
        this.props.actions.uploadGalleryItem(newItem);
        this.props.actions.setGalleryItem(newItem);
    };


    onLoadFileError = (response) => {
        let errors = this.state.errors;
        let values = typeof response !== 'string' ? Object.values(response) : [response];
        errors.push(values);
        this.setState({errors: errors, isLoading: false});
        this.fileField.current.value = "";
    };

    checkItemType = (e, imageType) =>{
        if (!imageType) {
            this.setState({errors: ['Select where to save the image']});
            e.preventDefault();
        }
    };

    handleOnDataChange = (e, imageType) =>{
        if (!imageType) {
            this.setState({errors: ['Select where to save the image']});
            return;
        }
        if (!e.target.files[0]){
            return;
        }
        this.setState({errors: [], isLoading: true});
        this.uploadImage(e.target.files[0], imageType);
    };

    switchToIndexScreen = () => {
        let gallery = this.props.gallery;
        gallery['screen'] = 'index';
        if (this.props.gallery.mode === 'admin') { gallery['reUploadItem'] = null; }
        this.props.actions.changeGallerySettings(gallery);
    };

    renderErrors = () => {
        if (Object.keys(this.state.errors).length === 0)
            return null;

        return(
            <Fragment>
                <br />
                <ul className='errors'>
                    {Object.values(this.state.errors).map(function(error, i) {
                        return (
                            <li key={i} className="list-group-error">
                                <ErrorMessage message={error}/>
                            </li>)
                    })}
                </ul>
            </Fragment>
        )
    };

    render() {
        let showTypeSelector = !(this.props.gallery.reUploadItem || this.props.gallery.itemType) && this.props.gallery.mode === 'admin';
        let itemType = this.props.gallery.reUploadItem ? this.props.gallery.reUploadItem.itemType : this.props.gallery.itemType || this.state.selectedType;
        return(
            <Fragment>
                {this.state.isLoading ? <Loader /> : null}
                <div className="grid-x control-area">
                    <div className="large-3 cell flex-center" style={{height:'50px'}}>
                        <span className="text-button-strong secondary-header" style={{marginRight: '20px'}}>Upload</span>
                        <span className="text-button-strong primary-header" onClick={this.switchToIndexScreen}>Library</span>
                    </div>
                </div>
                <div className='grid-x upload-area align-middle'>

                    <div className="medium-offset-3 medium-6 cell text-center">
                        {showTypeSelector ?
                            <Fragment>
                                <div className="custom-form">
                                    <SelectField name='selectedType' placeholder="Select where to save the image" className='label-icon-right select-label'
                                                 onChange={this.onChangeFilter}
                                                 options={IMAGE_TYPES} optionsValue='value' optionsLabel='label'
                                                 selected={this.state.selectedType} />
                                </div>
                                <br />
                            </Fragment>: null
                        }
                        <span className="text-button-strong primary-header">Add a file</span>
                        <br />
                        <label htmlFor={this.state.id} className="button button-orange" style={{height:'auto'}}>Upload Files</label>
                        <input accept={FORMATS_ACCEPTED[itemType]} className="input-file" id={this.state.id} type="file"
                               style={{display: 'none'}}
                               onClick={(e) => this.checkItemType(e, itemType)}
                               onChange={(e) => this.handleOnDataChange(e, itemType) } name='file[data]' ref={this.fileField} />
                        {this.renderErrors()}
                        <p>Formats accepted: {FORMATS_ACCEPTED[itemType]}</p>
                        {FILE_REQUIREMENTS[itemType]}
                    </div>
                </div>
            </Fragment>
        )
    }

}
function mapStateToProps(state) {
    return {gallery: state.gallery};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, galleryActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemUpload);