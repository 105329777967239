import React, {Component} from "react";
import Slider from "react-slick";
import {actions} from "react-fields";

const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 600,
    cssEase:  'ease-out',
    slidesToShow: 2,
    slidesToScroll: 1,
    className: 'studies-slider',
    responsive: [
        {
            breakpoint: 641,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class studiesCarousel extends Component {

    state = {
        articles: [],
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS
    };

    componentDidMount() {
        let _this = this;
        let url = `/api/press/${this.props.article.id}/similar`;

        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({articles: data});
            });
    }

    renderArticle = (article, i) => {
        return(
            <div className={`press__study-box`} key={`categoryArticle${i}`}>
                {/*<a href={article.full_path}>*/}
                    <div className="press__study-details press__study-details--white">
                        <h3 className="press__study-title"><a href={article.full_path}>{article.name}</a></h3>
                        <h5 className="press__item-date">{article.publish_date_formatted}</h5>
                    </div>
                    <div className="press__item-img">
                        <a href={article.full_path}><img src={`${article.featured_image.medium_path}`} alt={article.image_alt || article.name} /></a>
                    </div>
                {/*</a>*/}
            </div>
        )
    };

    render() {
        return(
            <div className='grid-x'>
                <div className='small-12 cell'>
                 <Slider {...this.state.settings}>
                    {this.state.articles.map((article, i) => { return this.renderArticle(article, i); })}
                </Slider>
                </div>
            </div>
        )
    }

}