import React, {Component} from "react";
import {actions} from "react-fields";

const HEADERS = {news: 'Other News', study: 'Other Studies', press: 'Other Press Releases'};

export default class SidebarPressArticles extends Component {

    state = {
        articles: []
    };

    componentDidMount() {
        let _this = this;
        let url = `/api/press/${this.props.article.id}/similar`;

        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({articles: data});
            });
    }

    renderArticle = (article, i) => {
        return(
            <div className={`article-side`} key={`categoryArticle${i}`}>
                <img src={article.featured_image_absolute_path} className="category-image" alt={article.name}/>
                <div className='category-details'>
                    <a href={`/press${article.path}`} className="press__release--title">{article.name}</a>
                    <p className="text-light upload-date">{article.publish_date_formatted}</p>
                </div>
            </div>
        )
    };

    render() {
        const categoryPath = `/${this.props.article.press_type}`;

        return(
            <div className="side-nav-container">
                <div className='side-navigation'>
                    <div className='grid-x career-banner-side article-side'>
                        <div className="large-12 cell">
                            <a href={categoryPath}>
                                <p className="press__release--header-title">{HEADERS[this.props.article.press_type]}</p>
                            </a>
                        </div>
                    </div>
                    {this.state.articles.map((article, i) => { return this.renderArticle(article, i); })}
                </div>
            </div>
        )
    }

}