import React from "react";
import SchemaOrganization from "../../partials/schemaOrganization";

export default function NewsItem(props) {

    const seoProps = {itemType: 'http://schema.org/Article', itemScope: true, itemProp: 'hasPart'};
    const seoImgProps = {itemType: 'http://schema.org/ImageObject', itemScope: true, itemProp: 'image'};

    return (
        <div className="press__item" {...seoProps}>
            <meta itemProp="headline" content={props.article.name} />
            <meta itemProp="articleSection" content='News' />
            <meta itemProp="datePublished" content={props.article.publish_date_schema} />
            <meta itemProp="dateModified" content={props.article.modified_date} />
            <meta itemProp="mainEntityOfPage" content={""} itemScope itemType="http://schema.org/WebPage" itemID={props.article.url} />
            <SchemaOrganization itemProp={'author'} />
            <SchemaOrganization itemProp={'publisher'} />

            <div className="grid-x">
                <div className="medium-8 small-12 medium-order-1 small-order-2 cell">
                    <a rel="nofollow" href={props.article.url} target={'_blank'}>
                      <h3 className="press__item-title">{props.article.name}</h3>
                    </a>
                    <h5 className="press__item-date">{props.article.publish_date_formatted}</h5>
                </div>
                <div className="medium-4 small-12 medium-order-2 small-order-1 cell press__item-logo flex-end" {...seoImgProps}>
                    <meta itemProp="url" content={props.article.featured_image.original_path} />
                    <meta itemProp="width" content="700" />
                    <meta itemProp="height" content="400" />
                    <a rel="nofollow" href={props.article.url} target={'_blank'}>
                        <img alt={props.article.featured_image.alt}
                             srcSet={`${props.article.featured_image.medium_path} 450w, ${props.article.featured_image.thumbnail_path} 224w`}
                             sizes="(min-width: 300px) and (max-width: 639px) 450px, 224px"
                             src={props.article.featured_image.medium_path}/>
                    </a>
                </div>
            </div>
        </div>
    )

}